import Controller from './base_controller';
import api from '../services/api';

export default class extends Controller {
  static baseTargets = ['form'];

  get authenticityToken() {
    return this.formInputs.find(input => input.name === 'authenticity_token').value;
  }

  get formInputs() {
    return $(this.formTarget).serializeArray();
  }

  get method() {
    return this.text.id ? 'put' : 'post';
  }

  get url() {
    const url = this.formTarget.getAttribute('action');
    return this.text.id ? this.setIdInUrl(url) : url;
  }


  initialize() {
    // we can't reach the elements in modal with normal target methods, so we find it with Nodes
    this.modalTarget = this.element.firstElementChild;
    [, this.modalBodyTarget] = this.modalTarget.childNodes;
    this.messageTarget = document.createElement('div');
    this.formTarget.prepend(this.messageTarget);
    this.type = this.identifier.replace('save-', '');
    this.text = {};
    this.underscoredType = this.type.replace(/-/g, '_');

    this.afterFullLoad(() => {
      this.filterController = this.findController(`filter-${this.type}`);
    });
  }

  onSuccess(data, changeFilterFn) {
    const flash = data.flash[0];
    const error = flash[0] === 'error';
    this.showMessage(flash, error);
    if (error) return;

    this.text.id = data.id;
    changeFilterFn.call(this.filterController, this.text);
    setTimeout(() => {
      this.messageTarget.innerHTML = '';
      this.setModalVisibility('hide');
    }, 2000);
  }

  async save(event) {
    event.preventDefault();
    window.tinyMCE.triggerSave();
    this.setValues();
    const params = {
      [this.underscoredType]: this.text,
      authenticity_token: this.authenticityToken,
    };

    const data = await api.httpCall(this.method, this.url, params, () => alert('Tekst niet opgeslagen')); // eslint-disable-line
    if (!data) return;
    this.onSuccess(data, this.filterController.addText);
  }

  setIdInUrl(url) {
    return url.replace(/\/\d*$/, `/${this.text.id}`);
  }

  async destroy(event) {
    event.stopPropagation();
    event.preventDefault();
    if (confirm(event.target.dataset.confirm)) { // eslint-disable-line
      const data = await api.delete(`${this.setIdInUrl(event.target.href)}`, {
        'X-CSRF-Token': this.authenticityToken,
      });
      this.onSuccess(data, this.filterController.removeText);
    }
  }

  setModalVisibility(visibility) {
    $(this.modalTarget).modal(visibility);
  }

  setText(text) {
    this.text = text;
    this.setContent();
  }

  setValues() {
    const regex = new RegExp(`${this.underscoredType}\\[(\\w*?)\\]`);
    this.formInputs.filter((input) => {
      const { name } = input;
      return name.indexOf(this.underscoredType) !== -1 && name.indexOf('[id]') === -1;
    }).forEach((input) => {
      this.text[input.name.replace(regex, '$1')] = input.value;
    });
  }

  showMessage(flash, error) {
    const classAttr = error ? 'error' : 'success';
    this.messageTarget.innerHTML = `<div class="alert fade in alert-${classAttr}">${flash[1]}<button class="close" data-dismiss="alert">&times;</button></div>`;
    $(this.modalBodyTarget).animate({ scrollTop: 0 }, 500);
  }
}
