import Controller from './base_controller';
import match from '../utils/match';

export default class extends Controller {
  static targets = ['all', 'query'];

  initialize() {
    this.toggledOpen = false;
    this.afterFullLoad(() => {
      this.toggleLinks = this.element.getElementsByClassName('togglelink');
    });
  }

  filter() {
    if (!this.hasQueryTarget && !this.queryTarget.value) return;
    if (this.toggledOpen === false) {
      this.toggleLinks.forEach((link) => {
        if (!link.getElementsByClassName('icon-minus-sign').length > 0) {
          link.click();
        }
      });
      this.toggledOpen = true;
    }

    this.allTargets.forEach((target) => {
      if (match(target.dataset.value, this.queryTarget.value)) {
        target.style.display = 'block';
      } else {
        target.style.display = 'none';
      }
    });
  }
}
